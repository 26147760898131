import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import FormServicos from "../../components/servicos/formServicos"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"
import Intro from "../../components/servicos/intro"
import IntroMobile from "../../components/servicos/introMobile"
import ServiceTextDoctor from "../../components/servicos/ServiceTextDoctor"
import ServiceTextDoctorMobile from "../../components/servicos/ServiceTextDoctorMobile"
import BannerSlideInfo from "../../components/servicos/BannerSlideInfo"
import BannerSlideInfoMobile from "../../components/servicos/BannerSlideInfoMobile"
import BannerTextInfo from "../../components/servicos/BannerTextInfo"
import BannerTextInfoMobile from "../../components/servicos/BannerTextInfoMobile"
import FormServicosMobile from "../../components/servicos/formServicosMobile"

const OclusaoPage = ({ data }) => (
  <Layout>
    <SEO
      title="Oclusão"
      description={data.oclusaoJson.intro.text}
    />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <BannerTextInfoMobile data={data.oclusaoJson.intro} />
        <IntroMobile data={data.oclusaoJson.intro} videoSection src={data.oclusaoJson.content.videoFinal} />
        <BannerSlideInfoMobile info={data.oclusaoJson.content} />
        <ServiceTextDoctorMobile doctor={data.oclusaoJson.doctor} data={data.oclusaoJson} />
        <FormServicosMobile nome="Oclusão" />
        <FooterMobile data={data.globalJson.footer} />
      </>
    ) : (
      <>
        <Navbar />
        <BannerTextInfo data={data.oclusaoJson.intro} />
        <Intro data={data.oclusaoJson.intro} videoSection src={data.oclusaoJson.content.videoFinal} />
        <BannerSlideInfo info={data.oclusaoJson.content} />
        <ServiceTextDoctor doctor={data.oclusaoJson.doctor} data={data.oclusaoJson} />
        <FormServicos nome="Oclusão" />
        <Footer data={data.globalJson.footer} />
      </>
    )}
  </Layout>
)

export default OclusaoPage

export const Json = graphql`
  query oclusao {
    oclusaoJson {
      intro {
        text
        title
        banner
        intro
      }
      consult
      doctor {
        name
        cargo
        omd
        img 
      }
      content {
        videoFinal {
          title
          video {
            publicURL
          }
          poster {
            publicURL
          }
        }
        slide {
          img
          step
          subtitle
          title
        }
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`


